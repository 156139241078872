import React, { useState, Suspense, useRef, useEffect, useCallback, useMemo } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Environment, OrbitControls, OrthographicCamera, Loader, useProgress } from '@react-three/drei';
import { ClosedCookie } from './components/ClosedCookie';
import { LeftCookieOpening } from './components/LeftCookieOpening';
import { RightCookieOpening } from './components/RightCookieOpening';
import { PaperOpening } from './components/PaperOpening';
import { EnvTable } from "./components/EnvTable";
import { EnvFood } from "./components/EnvFood";
import { EnvCushion } from "./components/EnvCushion";
import { EnvCarpet } from "./components/EnvCarpet";
import { EnvDivider } from "./components/EnvDivider";
import InfiniteWoodenGrid from './components/InfiniteWoodenGrid';
import { fortunes } from './fortuneMessages';


const useAudio = (url) => {
    const [audio] = useState(new Audio(url));

    const play = useCallback(() => {
        audio.currentTime = 0; // Reset to start
        audio.play();
    }, [audio]);

    return play;
};

const CameraController = React.memo(() => {
    const { camera } = useThree();
    const controlsRef = useRef();

    useEffect(() => {
        if (controlsRef.current) {
            controlsRef.current.target.set(0, -2, 0);
            controlsRef.current.update();
        }
    }, []);

    return (
        <OrbitControls
            ref={controlsRef}
            camera={camera}
            enablePan={false}
            enableZoom={true}
            minZoom={25}
            maxZoom={50}
            minPolarAngle={Math.PI / 6}
            maxPolarAngle={Math.PI * 5 / 6}
            minAzimuthAngle={-Math.PI * 2 / 3}
            maxAzimuthAngle={Math.PI * 2 / 3}
        />
    );
});

const Scene = React.memo(({ isOpen, currentFortune, toggleCookie }) => (
    <>
        <OrthographicCamera makeDefault position={[0, 10, 50]} zoom={25} />
        <CameraController />
        <Environment preset="sunset" />
        <EnvTable scale={[40, 40, 40]} position={[0, -18.7, 0]} />
        <EnvFood scale={[40, 40, 40]} position={[0, -18.7, 0]} />
        <EnvCarpet scale={[40, 40, 40]} position={[0, -18.7, 0]} />
        <EnvDivider scale={[20, 20, 20]} position={[0, -18.7, -30]} />
        <EnvCushion scale={[40, 40, 40]} position={[0, -18.7, 0]} />
        <InfiniteWoodenGrid position={[0, -18.7, 0]} scale={[0.5, 0.5, 0.5]} textureSize={20} />
        {isOpen ? (
            <>
                <LeftCookieOpening scale={[0.1, 0.1, 0.1]} position={[0, -2, 0]} onClick={toggleCookie} />
                <RightCookieOpening scale={[0.1, 0.1, 0.1]} position={[0, -2, 0]} onClick={toggleCookie} />
                <PaperOpening
                    scale={[0.1, 0.1, 0.1]} position={[0, -2, 0]}
                    fortuneText={currentFortune}
                    onClick={toggleCookie}
                />
            </>
        ) : (
            <ClosedCookie scale={[0.1, 0.1, 0.1]} position={[0, -2, 0]} onClick={toggleCookie} />
        )}
    </>
));

const ToggleButton = React.memo(({ isOpen, onClick }) => (
    <button
        onClick={onClick}
        style={{
            position: 'absolute',
            bottom: '13%',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: '#ffffff',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            borderRadius: '20px',
            cursor: 'pointer',
            backdropFilter: 'blur(5px)',
            transition: 'all 0.3s ease',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
        }}
    >
        {isOpen ? 'Close Cookie' : 'Open Cookie'}
    </button>
));

function App() {
    const [isOpen, setIsOpen] = useState(false);
    const [currentFortune, setCurrentFortune] = useState("");

    const toggleCookie = useCallback(() => {
        if (!isOpen) {
            const randomIndex = Math.floor(Math.random() * fortunes.length);
            setCurrentFortune(fortunes[randomIndex]);
        }
        setIsOpen(prev => !prev);
    }, [isOpen]);

    return (
        <div style={{width: '100vw', height: '100vh', position: 'relative'}}>
            <Canvas style={{width: '100%', height: '100%'}}>
                <Suspense fallback={null}>
                    <Scene isOpen={isOpen} currentFortune={currentFortune} toggleCookie={toggleCookie} />
                </Suspense>
            </Canvas>
            <Loader />
            <ToggleButton isOpen={isOpen} onClick={toggleCookie} />
            <a
                href="https://x.com/hershdhillon"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    color: 'white',
                    textDecoration: 'none',
                    fontSize: '14px',
                    opacity: 0.7,
                    transition: 'opacity 0.3s ease'
                }}
            >
                made with ❤️ for the internet!
            </a>
        </div>
    );
}

export default App;