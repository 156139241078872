import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function EnvTable(props) {
    const { nodes, materials } = useGLTF('/table_env.glb')
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.TableZasikiShape_TableZasiki_0.geometry}
                material={materials.TableZasiki}
                scale={0.01}
            />
        </group>
    )
}

useGLTF.preload('/table_env.glb')
