import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function EnvFood(props) {
    const { nodes, materials } = useGLTF('/food_env.glb')
    return (
        <group {...props} dispose={null}>
            <group scale={0.01}>
                <group position={[2.907, 38.945, -2.504]}>
                    <group position={[-45.282, 0.17, 6.433]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.AmazakeShape_Amazake_0.geometry}
                            material={materials.Amazake}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.AmazakeShape_Yunomi_0.geometry}
                            material={materials.Yunomi}
                        />
                    </group>
                    <group position={[38.449, 0.17, 6.18]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Amazake_dup_2Shape_Amazake_0.geometry}
                            material={materials.Amazake_0}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Amazake_dup_2Shape_Yunomi_0.geometry}
                            material={materials.Yunomi_0}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Coaster_A_dup_2Shape_Plate_0.geometry}
                            material={materials.Plate_1}
                        />
                    </group>
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Coaster_AShape_Plate_0.geometry}
                        material={materials.Plate}
                        position={[-45.282, 0.17, 6.433]}
                    />
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.Coaster_BShape_GreenTea_0.geometry}
                        material={materials.GreenTea}
                        position={[-24.39, 1.005, -22.075]}
                    />
                    <group position={[16.649, 0.17, 23.225]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.DorayakiShape_Dorayaki_0.geometry}
                            material={materials.Dorayaki}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.DorayakiShape_Plate_0.geometry}
                            material={materials.Plate_2}
                        />
                    </group>
                    <group position={[-24.39, 1.005, -22.075]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.GreenTeaShape_Cup_0.geometry}
                            material={materials.material}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.GreenTeaShape_GreenTea_0.geometry}
                            material={materials.GreenTea_0}
                        />
                    </group>
                    <group position={[-22.021, 0.17, 23.691]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.HanabiraMochiShape_HanabiraMochi_0.geometry}
                            material={materials.HanabiraMochi}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.HanabiraMochiShape_Plate_0.geometry}
                            material={materials.Plate_3}
                        />
                    </group>
                    <group position={[21.628, 0.17, 7.347]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.HousekitouShape_Housekitou_Blue_0.geometry}
                            material={materials.Housekitou_Blue}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.HousekitouShape_Housekitou_Red_0.geometry}
                            material={materials.Housekitou_Red}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.HousekitouShape_Leaf_0.geometry}
                            material={materials.Leaf}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.HousekitouShape_Plate_0.geometry}
                            material={materials.Plate_4}
                        />
                    </group>
                    <group position={[27.921, 0.17, -16.787]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Coaster_B_dup_2Shape_GreenTea_0.geometry}
                            material={materials.GreenTea_2}
                            position={[9.76, 0.834, -5.898]}
                        />
                        <group position={[9.76, 0.834, -5.898]}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.GreenTea_dup_2Shape_Cup_0.geometry}
                                material={materials.Cup_0}
                            />
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.GreenTea_dup_2Shape_GreenTea_0.geometry}
                                material={materials.GreenTea_1}
                            />
                        </group>
                        <group position={[-5.776, 0.736, -5.699]}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.KamoSobaShape_Bowl_0.geometry}
                                material={materials.Bowl_0}
                            />
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.KamoSobaShape_KamoSoba_0.geometry}
                                material={materials.KamoSoba}
                            />
                        </group>
                        <group position={[8.451, 0.821, 6.522]}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.Omelet_dup_2Shape_Omelet_0.geometry}
                                material={materials.Omelet_0}
                            />
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.Omelet_dup_2Shape_Wood_0.geometry}
                                material={materials.Wood_0}
                            />
                        </group>
                        <group position={[-8.273, 0.843, 6.577]}>
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.RiceBall_dup_2Shape_Plate_0.geometry}
                                material={materials.Plate_5}
                            />
                            <mesh
                                castShadow
                                receiveShadow
                                geometry={nodes.RiceBall_dup_2Shape_RiceBall_0.geometry}
                                material={materials.RiceBall_0}
                            />
                        </group>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.Tray_dup_2Shape_Tray_0.geometry}
                            material={materials.Tray_1}
                        />
                    </group>
                    <group position={[34.627, 0.17, 22.748]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.KamoUdonShape_Bowl_0.geometry}
                            material={materials.Bowl_1}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.KamoUdonShape_KamoUdon_0.geometry}
                            material={materials.KamoUdon}
                        />
                    </group>
                    <group position={[-42.554, 0.17, 22.415]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.KitsuneSobaShape_Bowl_0.geometry}
                            material={materials.Bowl_2}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.KitsuneSobaShape_KitsuneSoba_0.geometry}
                            material={materials.KitsuneSoba}
                        />
                    </group>
                    <group position={[-41.523, 1, -21.315]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.KitsuneUdonShape_Bowl_0.geometry}
                            material={materials.Bowl}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.KitsuneUdonShape_KitsuneUdon_0.geometry}
                            material={materials.KitsuneUdon}
                        />
                    </group>
                    <group position={[-27.493, 0.17, 7.003]} rotation={[0, 0.678, 0]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.MitarashiDangoShape_MitarashiDango_0.geometry}
                            material={materials.MitarashiDango}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.MitarashiDangoShape_Plate_0.geometry}
                            material={materials.Plate_6}
                        />
                    </group>
                    <group position={[-25.7, 0.991, -9.656]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.OmeletShape_Omelet_0.geometry}
                            material={materials.Omelet}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.OmeletShape_Wood_0.geometry}
                            material={materials.Wood}
                        />
                    </group>
                    <group position={[-42.423, 1.013, -9.6]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.RiceBallShape_Plate_0.geometry}
                            material={materials.Plate_0}
                        />
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.RiceBallShape_RiceBall_0.geometry}
                            material={materials.RiceBall}
                        />
                    </group>
                    <group position={[-0.55, 0.17, 6.673]}>
                        <mesh
                            castShadow
                            receiveShadow
                            geometry={nodes.SakuraMochiShape_SakuraMochi_0.geometry}
                            material={materials.SakuraMochi}
                            position={[-5.295, 0, 0]}
                        />
                    </group>
                    <mesh
                        castShadow
                        receiveShadow
                        geometry={nodes.TrayShape_Tray_0.geometry}
                        material={materials.Tray_0}
                        position={[-34.151, 0.17, -16.177]}
                    />
                </group>
            </group>
        </group>
    )
}

useGLTF.preload('/food_env.glb')
