import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function EnvCarpet(props) {
    const { nodes, materials } = useGLTF('/carpet_env.glb')
    return (
        <group {...props} dispose={null}>
            <group position={[-2.036, 0.001, -0.039]}>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_6.geometry}
                    material={materials['VELVET-GREEN__Budapest']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_7.geometry}
                    material={materials['VELVET-GREEN__Budapest']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_8.geometry}
                    material={materials['VELVET-GREEN__Budapest']}
                />
            </group>
            <group position={[2.054, 0, -0.005]}>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_10.geometry}
                    material={materials['VELVET-GREEN__Budapest']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_11.geometry}
                    material={materials['VELVET-GREEN__Budapest']}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_12.geometry}
                    material={materials['VELVET-GREEN__Budapest']}
                />
            </group>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_4.geometry}
                material={materials.kilim}
            />
        </group>
    )
}

useGLTF.preload('/carpet_env.glb')
