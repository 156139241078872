import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function EnvDivider(props) {
    const { nodes, materials } = useGLTF('/divider_env.glb')
    return (
        <group {...props} dispose={null}>
            <group position={[0, 0, -1.671]} rotation={[-Math.PI / 2, 0, 0]}>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.SM_GNJ_Foldingscreen_M_GNJ_Foldingscreen_0.geometry}
                    material={materials.M_GNJ_Foldingscreen}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={0.01}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/divider_env.glb')