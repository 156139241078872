import React, { useRef, useEffect, useMemo } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import * as THREE from 'three'

export function PaperOpening({ fortuneText = "Your Fortune", ...props }) {
    const group = useRef()
    const { nodes, materials, animations } = useGLTF('/paper_opening.glb')
    const { actions } = useAnimations(animations, group)

    useEffect(() => {
        const action = actions["Key.003Action"]
        action.loop = THREE.LoopOnce
        action.clampWhenFinished = true

        // Calculate the start time (e.g., 30% into the animation)
        const startTime = action.getClip().duration * 0.2

        // Set the initial time of the animation to skip the first part
        action.time = startTime

        // Play the animation from the new start time
        action.play().paused = false

        return () => action.stop()
    }, [actions])
    const texture = useMemo(() => {
        const canvas = document.createElement('canvas')
        canvas.width = 1024
        canvas.height = 1024
        const context = canvas.getContext('2d')
        context.fillStyle = 'white'
        context.fillRect(0, 0, canvas.width, canvas.height)

        // Set up text properties
        context.fillStyle = 'black'
        context.textAlign = 'center'
        context.textBaseline = 'middle'

        // Function to draw wrapped text
        function wrapText(context, text, maxWidth, maxHeight, initialFontSize) {
            let fontSize = initialFontSize
            context.font = `bold ${fontSize}px Arial`

            let lines = []
            let line = ''
            const words = text.split(' ')

            // Function to check if text fits within maxHeight
            const textFits = (lines, fontSize, lineHeight) => {
                return lines.length * lineHeight <= maxHeight
            }

            while (fontSize > 10) {
                lines = []
                line = ''
                context.font = `bold ${fontSize}px Arial`

                for (let n = 0; n < words.length; n++) {
                    const testLine = line + words[n] + ' '
                    const metrics = context.measureText(testLine)
                    const testWidth = metrics.width

                    if (testWidth > maxWidth && n > 0) {
                        lines.push(line.trim())
                        line = words[n] + ' '
                    } else {
                        line = testLine
                    }
                }
                lines.push(line.trim())

                if (textFits(lines, fontSize, fontSize * 1.2)) {
                    break
                }

                fontSize--
            }

            // Calculate total height of the text block
            const lineHeight = fontSize * 1.2
            const totalTextHeight = lines.length * lineHeight

            // Calculate starting Y position to center the text block
            let y = (canvas.height - totalTextHeight) / 2.2 + fontSize / 2

            // Draw the lines
            lines.forEach((line) => {
                context.fillText(line, canvas.width / 2, y)
                y += lineHeight
            })
        }

        // Draw the text
        wrapText(context, fortuneText, canvas.width * 0.8, canvas.height * 0.8, 60)

        const texture = new THREE.CanvasTexture(canvas)
        texture.flipY = false  // This corrects the upside-down issue
        return texture
    }, [fortuneText])

    const paperMaterial = useMemo(() => {
        const material = materials['Polygon Selection.1'].clone()
        material.map = texture
        return material
    }, [materials, texture])

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <mesh
                    name="PAPER_OPENING"
                    castShadow
                    receiveShadow
                    geometry={nodes.PAPER_OPENING.geometry}
                    material={paperMaterial}
                    morphTargetDictionary={nodes.PAPER_OPENING.morphTargetDictionary}
                    morphTargetInfluences={nodes.PAPER_OPENING.morphTargetInfluences}
                    position={[-0.211, -0.285, 0.275]}
                    rotation={[0, 0.001, 0.003]}
                    scale={0.1}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/paper_opening.glb')