import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function EnvCushion(props) {
    const { nodes, materials } = useGLTF('/cushion_env.glb')
    return (
        <group {...props} dispose={null}>
            <group scale={0.01}>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.CushionShape_Cushion_0.geometry}
                    material={materials.Cushion}
                    position={[-31.788, -2.481, 70.432]}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cushion_dup_2Shape_Cushion_0.geometry}
                    material={materials.Cushion_0}
                    position={[36.468, -2.481, 70.432]}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cushion_dup_3Shape_Cushion_0.geometry}
                    material={materials.Cushion_1}
                    position={[-31.788, -2.481, -70.684]}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cushion_dup_4Shape_Cushion_0.geometry}
                    material={materials.Cushion_2}
                    position={[36.468, -2.481, -70.684]}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/cushion_env.glb')
