import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function ClosedCookie(props) {
    const { nodes, materials } = useGLTF('/close_cookie_02.glb')
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.cookie_close.geometry}
                material={materials['phong1SG1.001']}
                position={[7.43, 3.635, -11.161]}
                rotation={[1.572, -0.049, 2.129]}
                scale={10}
            />
        </group>
    )
}

useGLTF.preload('/close_cookie_02.glb')
