import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import * as THREE from 'three'

export function RightCookieOpening(props) {
    const group = useRef()
    const { nodes, materials, animations } = useGLTF('/right_cookie_opening.glb')
    const { actions } = useAnimations(animations, group)

    useEffect(() => {
        const action = actions["Key.002Action"]

        // Set up the animation to play once and hold on last frame
        action.loop = THREE.LoopOnce
        action.clampWhenFinished = true

        // Set the initial time of the animation to skip the first part
        // Adjust this value to control how "open" the cookie starts
        const startTime = action.getClip().duration * 0.2 // Start at 30% of the animation

        // Play the animation from the new start time
        action.time = startTime
        action.play().paused = false

        return () => action.stop()
    }, [actions])

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <mesh
                    name="RIGHT_COOKIE_OPENING"
                    castShadow
                    receiveShadow
                    geometry={nodes.RIGHT_COOKIE_OPENING.geometry}
                    material={materials['phong1SG1.001']}
                    morphTargetDictionary={nodes.RIGHT_COOKIE_OPENING.morphTargetDictionary}
                    morphTargetInfluences={nodes.RIGHT_COOKIE_OPENING.morphTargetInfluences}
                    rotation={[0, 0.001, 0.003]}
                    scale={0.1}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/right_cookie_opening.glb')