import React, { useRef, useMemo } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';

const InfiniteWoodenGrid = ({ position = [0, 0, 0], rotation = [-Math.PI / 2, 0, 0], scale = [1, 1, 1], textureSize = 10 }) => {
    const meshRef = useRef();
    const woodTexture = useLoader(TextureLoader, '/wood_texture.jpg');

    // Create a repeating texture
    woodTexture.wrapS = woodTexture.wrapT = THREE.RepeatWrapping;
    woodTexture.repeat.set(textureSize, textureSize);

    const uniforms = useMemo(() => ({
        uTexture: { value: woodTexture },
        uTime: { value: 0 },
        uTextureSize: { value: textureSize },
    }), [woodTexture, textureSize]);

    useFrame((state) => {
        if (meshRef.current) {
            meshRef.current.material.uniforms.uTime.value = state.clock.getElapsedTime();
        }
    });

    const vertexShader = `
    varying vec2 vUv;
    uniform float uTextureSize;
    void main() {
      vUv = uv * uTextureSize;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

    const fragmentShader = `
    uniform sampler2D uTexture;
    uniform float uTime;
    uniform float uTextureSize;
    varying vec2 vUv;

    void main() {
      vec2 repeatUv = fract(vUv);
      vec4 tex = texture2D(uTexture, repeatUv);
      
      // Add shininess
      float shine = pow(sin(vUv.x + uTime) * 0.5 + 0.5, 10.0);
      shine += pow(sin(vUv.y + uTime) * 0.5 + 0.5, 10.0);
      
      gl_FragColor = mix(tex, vec4(1.0), shine * 0.2);
    }
  `;

    return (
        <mesh ref={meshRef} position={position} rotation={rotation} scale={scale}>
            <planeGeometry args={[1000, 1000]} />
            <shaderMaterial
                vertexShader={vertexShader}
                fragmentShader={fragmentShader}
                uniforms={uniforms}
            />
        </mesh>
    );
};

export default InfiniteWoodenGrid;